import React from "react";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ServicesList from "../../components/services/services-list";
import { useStaticQuery, graphql } from "gatsby";

const ServicesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "home-hero-2.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	return (
		<Layout>
			<GatsbySeo
				title="Our commercial photography services | Sarah Bennett Photography"
				description="Make your business stand out with our professional photography services. Food, Product, Business, Headshot and Advertising photography"
				language="en"
				canonical="https://www.sarah-bennett.com/services"
				openGraph={{
					url: "https://www.sarah-bennett.com/services",
					title:
						"Food Photography East & West Sussex | Sarah Bennett Photography",
					description:
						"Food Photography is a particular skill. Food needs to look fresh, tasty and jump off the page. I style and shoot in my Eastbourne studio. Click to see my work.",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Our commercial photography services in East and West Sussex",
						},
					],
				}}
			/>

			<ServicesList />
		</Layout>
	);
};

export default ServicesPage;
