import Link from "./link";
import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const ServiceCard = ({ name, description, url, img, hideBtn }) => {
	return (
		<Card className="border-0 p-lg-6 pb-md-3">
			<Card.Img as={GatsbyImage} image={img} style={{ height: "360px" }} />
			<Card.Title
				as={Link}
				to={url}
				className="text-start text-dark text-md-center text-uppercase mt-5 fw-bold"
				style={{ fontSize: "1.75rem" }}
			>
				{name}
			</Card.Title>
			<Card.Body className="px-0">{description}</Card.Body>
			<Card.Footer className={`text-center px-0 ${hideBtn && "d-none"}`}>
				<Button variant="primary cta-btn" as={Link} to={url}>
					Read More
				</Button>
			</Card.Footer>
		</Card>
	);
};

export default ServiceCard;
