import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServiceCard from "../../components/serviceCard";
import { useStaticQuery, graphql } from "gatsby";

const ServicesList = () => {
	const data = useStaticQuery(graphql`
		query {
			businessBrandingImg: file(
				relativePath: { eq: "services/business-branding.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			proHeadshotsImg: file(relativePath: { eq: "services/headshots.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						transformOptions: { fit: CONTAIN }
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			productImg: file(relativePath: { eq: "services/product.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			foodImg: file(relativePath: { eq: "services/food.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			creativeImg: file(relativePath: { eq: "services/creative.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			trainingImg: file(relativePath: { eq: "services/training.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	return (
		<section className="pt-lg-5 bg-white">
			<Container>
				<Row className="mb-md-6">
					<Col>
						<h1 className="text-start text-md-center">
							Our Commercial Photography Services
						</h1>
						<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.businessBrandingImg.childImageSharp.gatsbyImageData}
							name="Business Branding"
							description="As a commercial photographer I know how to capture the very essence of your business and present it visually so you will have a stock of unique images that you can use on your website on in your social media campaigns. Take a look at some of the branding photography I have taken."
							url="/services/business-branding"
						/>
					</Col>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.proHeadshotsImg.childImageSharp.gatsbyImageData}
							name="Professional Headshots"
							description="I love working with people in East Sussex and the surrounding area and helping people to relax so that I can take headshots that truly bring out their personality. Why not take a look at some professional headshots I have taken for other people."
							url="/services/personal-branding"
						/>
					</Col>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.productImg.childImageSharp.gatsbyImageData}
							name="Product Photography"
							description="Do you have a new product you want to promote? Or maybe you took some pictures yourself and you now want to create a more professional image. Are you looking for someone who can take crisp, sleek images of your products? Then look no further!"
							url="/services/product-photography"
						/>
					</Col>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.foodImg.childImageSharp.gatsbyImageData}
							name="Food Photography"
							description="Do you want to have photos of your food that look as good as the food tastes? From sizzling, mouth-watering burgers to cream-filled indulgent desserts that even though the most calorie-obsessed consumer can’t resist. Whatever your product, I can create images that make the senses come alive!"
							url="/services/food-photography"
						/>
					</Col>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.creativeImg.childImageSharp.gatsbyImageData}
							name="Creative Management"
							description="Do you need images to support your advertising and marketing campaigns? Would you like to stand out from the crowd with original images that promote your products or services? I love working with clients in and around Eastbourne and Sussex to produce images that get their message across."
							url="/services/creative-management"
						/>
					</Col>
					<Col xs={12} md={6} className="mb-8 mb-md-0">
						<ServiceCard
							img={data.trainingImg.childImageSharp.gatsbyImageData}
							name="Photography Training"
							description="For businesses owners wanting to improve their basic photography skills, I run The Social Media Photography Course. The Business of Photography Course is for new photographers and any photographer who either wants to launch a new business or rebrand or relaunch their current business. Perhaps you can relate?"
							url="/services/photography-training"
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ServicesList;
